import { Button } from "@chakra-ui/button"
import { Image } from "@chakra-ui/image"
import { Box, Text, Spacer } from "@chakra-ui/layout"
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/input"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import logo from "../../../images/sensetechLogo.png"
import "./Navbar.css"
import firebase from "../../../firebase"
import {
  faEnvelopeOpen,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons"
import swal from "sweetalert"

const Navbar = ({ showLogin = false }) => {
  const [clicked, setClicked] = useState(false)
  const [shadow, setShadow] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [adminData, setAdminData] = useState([])
  const [adminFormData, setAdminFormData] = useState({
    email: "",
    password: "",
  })
  const [userData, setUserData] = useState({ email: "", name: "" })
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => setShowPassword(!showPassword)

  const handleClick = () => {
    setClicked(!clicked)
  }

  const handleSignIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(adminFormData.email, adminFormData.password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user
        setUserData({ email: user.email, name: user.displayName })
        sessionStorage.setItem("email", user.email)
        sessionStorage.setItem("name", user.displayName)
        onClose()
        setAdminFormData({ email: "", password: "" })
      })
      .catch(error => {
        const errorMessage = error.message
        swal("Error!", errorMessage, "error")
      })
  }

  useEffect(() => {
    window.addEventListener("scroll", e => {
      if (window.pageYOffset > 0) {
        setShadow(true)
      } else {
        setShadow(false)
      }
    })
  }, [shadow])

  // Load admin data from firestore
  useEffect(() => {
    firebase
      .firestore()
      .collection("admin_email")
      .onSnapshot(snapshot => {
        const listItems = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }))
        setAdminData(listItems)
      })
    return adminData
  }, [adminData])

  const isAdmin = adminData.find(
    el => el.email === sessionStorage.getItem("email")
  )

  const handleLogout = () => {
    firebase.auth().signOut()
    sessionStorage.removeItem("name")
    sessionStorage.removeItem("email")
    setUserData({ email: "", name: "" })
  }

  useEffect(() => {
    if (sessionStorage.getItem("name")) {
      setUserData({
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
      })
    }
  }, [])

  return (
    <Box
      className={shadow ? "add-shadow navigation" : "navigation"}
      position="fixed"
      top="0"
      zIndex="10"
      width="100%"
    >
      <Box className="NavbarItems" px="15px">
        <AnchorLink to="/">
          <Image className="navbar__logo" src={logo} alt="SENSETECH" />
        </AnchorLink>
        <div className="menu-icon" onClick={handleClick}>
          {!clicked ? (
            <FontAwesomeIcon icon={faBars} />
          ) : (
            <FontAwesomeIcon icon={faTimes} />
          )}
        </div>
        <ul className={clicked ? "nav-menu nav__active" : "nav-menu"}>
          <li key="1">
            <AnchorLink className="nav-links" to="/">
              Home
            </AnchorLink>
          </li>
          <li key="2">
            <AnchorLink className="nav-links" to="/services">
              Services
            </AnchorLink>
          </li>
          <li key="3">
            <AnchorLink className="nav-links" to="/about">
              About Us
            </AnchorLink>
          </li>
          {isAdmin?.email && showLogin && (
            <li key="4">
              <AnchorLink className="nav-links" to="/admin">
                Admin
              </AnchorLink>
            </li>
          )}
          <li key="5" className="nav-links">
            {showLogin && (
              <Text
                cursor="pointer"
                onClick={userData?.email ? handleLogout : onOpen}
              >
                {userData?.email
                  ? "Logout" + " " + userData.email.split("@")[0]
                  : "Login"}
              </Text>
            )}
            <Modal
              blockScrollOnMount={false}
              isCentered
              isOpen={isOpen}
              onClose={onClose}
              closeOnOverlayClick={false}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Login</ModalHeader>
                <ModalCloseButton />

                <ModalBody p="70px 0 100px 0">
                  {/* NOTE: TODO: WORKING HERE */}
                  <InputGroup width="70%" margin="0 auto" mb="0" pb="0">
                    <InputLeftElement
                      pointerEvents="none"
                      children={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                    />
                    <Input
                      onChange={e =>
                        setAdminFormData({
                          ...adminFormData,
                          email: e.target.value,
                        })
                      }
                      value={adminFormData.email}
                      type="email"
                      placeholder="Enter Email"
                    />
                  </InputGroup>

                  <InputGroup width="70%" margin="20px auto" pb="0">
                    <Input
                      pr="4.5rem"
                      type={showPassword ? "text" : "password"}
                      onChange={e =>
                        setAdminFormData({
                          ...adminFormData,
                          password: e.target.value,
                        })
                      }
                      placeholder="Enter password"
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        outline="none"
                        backgroundColor="transparent"
                        size="sm"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>

                  <InputGroup width="70%" margin="20px auto">
                    <Button
                      bg="#02073e"
                      colorScheme="#02073e"
                      variant="solid"
                      width="100%"
                      onClick={handleSignIn}
                    >
                      Login
                    </Button>
                  </InputGroup>
                  {/* NOTE: TODO: WORKING HERE */}
                </ModalBody>
              </ModalContent>
            </Modal>
          </li>
        </ul>
        <Spacer />
        <Box
          className={
            clicked
              ? "nav__btn--container "
              : "nav__btn--container nav__btnActive"
          }
        >
          <AnchorLink to="/contact">
            <Button colorScheme="#02073e" className="btn__primary nav__btn">
              Contact Now
            </Button>
          </AnchorLink>
        </Box>
        <Spacer />
      </Box>
    </Box>
  )
}

export default Navbar
