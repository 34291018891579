import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/database"
import "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDaPZLFLENwbFwz2x7YFIWQRH8mdzpVgb8",
  authDomain: "sensetech-bd898.firebaseapp.com",
  projectId: "sensetech-bd898",
  storageBucket: "sensetech-bd898.appspot.com",
  messagingSenderId: "953106406152",
  appId: "1:953106406152:web:32bf8d7ac680113480eabd",
  measurementId: "G-R140DH7QRF",
}
firebase.initializeApp(firebaseConfig)

export default firebase
